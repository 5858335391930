import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";

const Footer = () => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className="footer-section">
      <div className="container">
        <div className="row">
          {/*  Start:About  */}
          <div style={{ width: "100%" }} className="col-lg-3 col-sm-12">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              className="widget"
            >
              <div className="footer_p">
                <p className="footer_para">
                  {" "}
                  Viven Aviation, your premier destination for comprehensive aviation solutions. Whether you're seeking aircraft charter, aircraft sales, aircraft leasing, aircraft financing, aircraft engines or parts, place your trust in our expertise. Elevate your aviation ventures with us today.
                </p>
              </div>
              <div
                style={{ display: "flex", justifyContent: "center" }}
                className="footer_socil"
              >
                <ul className="list-icons link-list footer_soc">
                  <li>
                    <a
                      href="//facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="//twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      href="//instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/*  End:About  */}

          <button
            className={`scrollup ${scroll > top ? "show" : ""}`}
            onClick={() => scrollToTop()}
          >
            <span className="icon-glyph-203"></span>
          </button>
        </div>
      </div>
      {/*  Start:Subfooter  */}
      <div className="subfooter">
        <p>
          2020 © Copyright{" "}
          <Link to={process.env.PUBLIC_URL + "/"}>VIVEN AVIATION.</Link> All
          rights Reserved.
        </p>
      </div>
      {/* End:Subfooter  */}
    </footer>
  );
};

export default Footer;
