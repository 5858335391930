import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Slides from "../components/Slides";
import AboutContent from "../components/AboutContent";
import ServiceContent from "../components/ServiceContent";
import Funfact from "../components/Funfact";
import WorkProcess from "../components/WorkProcess";
import ChooseUs from "../components/ChooseUs";
import ProductsList from "../components/ProductsList";
import PricingList from "../components/PricingList";
import GalleryContent from "../components/GalleryContent";
import Team from "../components/Team";
import Testimonial from "../components/Testimonial";
import BlogList from "../components/BlogList";
import ClinetCarousel from "../components/ClinetCarousel";
import ContactForm from "../components/ContactForm";

import { Fade, Slide } from "react-reveal";
import GalleryTwo from "./GalleryTwo";
import { useLocation } from "react-router-dom";

const Home = () => {

  const location = useLocation();

  useEffect(() => {
    // Function to scroll to the "Team" section if the query parameter is present
    const scrollToTeamSection = () => {
      const params = new URLSearchParams(location.search);
      if (params.get("scrollTo") === "team-section") {
        const teamSection = document.getElementById("team-section");
        if (teamSection) {
          window.scrollTo({
            top: teamSection.offsetTop,
            behavior: "smooth",
          });
        }
      }
    };

    scrollToTeamSection();
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>Viven Aviation | Your reliable resource for aviation.</title>
        <meta name="description" content="Viven Aviation | Your reliable resource for aviation" />
      </MetaTags>
      <LayoutOne>
        <Fade big>
          <Slides />
        </Fade>
        <Slide bottom>
          <AboutContent />
        </Slide>
        <ServiceContent />
        <ChooseUs />
        <div id="team-section"> {/* Add this ID */}
          <Team />
        </div>
        <GalleryTwo />
        <ContactForm />
      </LayoutOne>
    </Fragment>
  );
};

export default Home;
