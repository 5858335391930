import React from "react";
import img from "../assets/img/viven-asset-2.jpg";

const AboutContent = () => {
  return (
    <section className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="about_img">
              <img style={{ maxHeight: "600px" }} src={img} alt="about" />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about_item_tb">
              <div className="about_item_tbcell">
                <div className="base-header base_header_left">
                  <small>Welcome to Viven Aviation</small>
                  <h3>Elevate your aviation ventures with Viven Aviation </h3>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <p>
                    PT. VIVEN GLOBAL AVIASI is an aviation company based in
                    Indonesia, trading as Viven Aviation was established in 2023
                    by a group of experts with solid aviation and financing
                    background. We are specializing in ACMI Leasing, Air
                    charter, Aircraft sales & Leasing, financing and aviation
                    consultanty services. Viven Aviation provides a
                    comprehensive range of reliable resources and solutions to
                    customers in Indonesia and to our global clients.
                  </p>
                </div>
                <div className="about_item">
                  <h4>Empowering Your Skies</h4>
                  <p>
                    Your all-inclusive hub for holistic aviation solutions. From
                    aircraft charter, aircraft sales to lease, aircraft
                    management and aircraft financing, we've got you covered.
                    Trust our expertise in aircraft parts, engines and rotable
                    components, and rely on our top-notch aviation consultancy
                    services.
                  </p>
                </div>
                <div className="about_item">
                  <h4>Taking Off Together</h4>
                  <p>
                    With over a decade of experience in the aviation industry,
                    we've honed our skills to perfection. Elevate your flying
                    experience with our trusted guidance and unwavering
                    commitment to excellence. Welcome to Viven Aviation, where
                    your dreams take flight.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*about_wrp*/}
        </div>
        {/* row */}
      </div>
      {/* container */}
    </section>
  );
};

export default AboutContent;
