import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import { toast } from "react-toastify";
import Breadcrumb from "../components/Breadcrumb";
import ContactMap from "../components/ContactMap";
import emailjs from "emailjs-com";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Viven Aviation | Contact Us</title>
          <meta
            name="description"
            content="Viven Aviation | Your reliable resource for aviation"
          />
        </MetaTags>
        <LayoutOne>
          <div className="contact-page">
            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Contact Us" />

            {/*====================  End of breadcrumb area  ================*/}

            {/*====================  Contact Form  area  ====================*/}
            <section className="contact-section">
              <div className="container">
                <div className="base-header">
                  <small>Get in touch</small>
                  <h3>Send Us Message</h3>
                </div>
                <div className="contact_wrp">
                  <div className="row">
                    <div className="col-md-8 col-sm-12 inner-contact">
                      <div className="contact-form">
                        <div id="message">
                          {this.state.flag ? (
                            <div className="alert alert-success">
                              <strong>{this.state.return_msg}</strong>
                            </div>
                          ) : null}
                        </div>
                        <form
                          name="contact-form"
                          id="contact-form"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6 col-sm-12">
                              <input
                                type="text"
                                id="name"
                                value={this.state.name}
                                onChange={this.handleChangeName}
                                className="con-field form-control"
                                placeholder="Name"
                              />
                              <span id="err">{this.state.name_err}</span>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                              <input
                                type="text"
                                className="con-field form-control"
                                value={this.state.email}
                                onChange={this.handleChangeEmail}
                                id="exampleInputEmail1"
                                placeholder="Email"
                              />
                              <span id="err">{this.state.email_err}</span>
                            </div>
                            <div className="col-lg-12 col-sm-12">
                              <input
                                type="text"
                                id="subject"
                                value={this.state.subject}
                                onChange={this.handleChangeSubject}
                                className="form-control con-field"
                                placeholder="Your Subject"
                              />
                              <span id="err">{this.state.subject_err}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-sm-12">
                              <textarea
                                name="comments"
                                id="comments"
                                value={this.state.message}
                                onChange={this.handleChangeMessage}
                                rows="6"
                                className="form-control con-field"
                                placeholder="Your Message"
                              ></textarea>
                              <span id="err">{this.state.message_err}</span>
                              <div className="submit-area">
                                <input
                                  type="button"
                                  id="submit"
                                  name="send"
                                  onClick={this.handleSubmit1}
                                  className="submit-contact submitBnt"
                                  value="Send"
                                />

                                <div id="simple-msg"></div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/*===  Contact Details  ===*/}
                    <div className="col-md-4 col-sm-12">
                      <div className="contact_pg_address">
                        <h3>
                          Hello there <br />
                          got a project?
                        </h3>
                        <div className="single_con_add">
                          <a href="#/">
                            <i className="icon-glyph-226"></i>
                          </a>
                          <p>
                            <span>Company Address :</span>
                          </p>
                          <p>
                            <span
                              style={{ textAlign: "start", marginLeft: "15px" }}
                            >
                              PT. Viven Global Aviasi, LEEON, Jalan M. Kahfi II
                              Nomor 23, RT 007/003, Kelurahan Cipedak, Kecamatan
                              Jagakarsa, Jakarta Selatan-12630, 
                              <br/>
                              Telp : 021-2780-5886
                            </span>
                          </p>
                        </div>
                        <div className="single_con_add">
                          <a href="#/">
                            <i className="icon-glyph-334"></i>
                          </a>
                          <p>
                            <span>Email Address :</span>
                          </p>
                          <p>
                            <span>sales@viven-aviation.com</span>
                          </p>
                        </div>
                        <div className="single_con_add">
                          <a style={{ minHeight: "70px" }} href="#/">
                            <i className="icon-glyph-33"></i>
                          </a>
                          <p>
                            <span>Contact Us :</span>
                          </p>

                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fa fa-phone"></i>
                            <span style={{ marginLeft: "-30px" }}>
                              +6282137557775
                            </span>
                          </p>
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fa fa-phone"></i>
                            <span style={{ marginLeft: "-30px" }}>
                              (+61) 021- 27805886 (office)
                            </span>
                          </p>
                          <p
                            style={{
                              marginLeft: "0px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fa fa-whatsapp"></i>
                            <span style={{ marginLeft: "-30px" }}>
                              +6282328306730
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*===  end: Contact Details  ===*/}
                  </div>

                  <div
                    style={{ width: "100%", marginTop: "50px" }}
                    className="col-sm-12 map-container"
                  >
                    {/* contact map */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15861.21856994824!2d106.79377029173088!3d-6.354595622172653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ee90e9a705d7%3A0x6f746b97d9b659c3!2sCipedak%2C%20Jagakarsa%2C%20South%20Jakarta%20City%2C%20Jakarta%2C%20Indonesia!5e0!3m2!1sen!2sin!4v1690813880104!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
            {/*====================  End: Contact Form area  ====================*/}
          </div>
        </LayoutOne>
      </Fragment>
    );
  }

  constructor() {
    super();
    this.state = {
      email: "",
      name: "",
      subject: "",
      message: "",
      email_err: "",
      name_err: "",
      subject_err: "",
      message_err: "",
      return_msg: "",
      flag: false,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
    var EmailReg = /(\w+)\s(\w+)/;
    if (e.target.value === "") this.setState({ email_err: "Required Field" });
    else if (EmailReg.test(e.target.value)) this.setState({ email_err: "" });
    else this.setState({ email_err: "Enter Valid Email" });
  }

  handleChangeName(e) {
    this.setState({ name: e.target.value });
    if (e.target.value === "") this.setState({ name_err: "Required Field" });
    else this.setState({ name_err: "" });
  }
  handleChangeSubject(e) {
    this.setState({ subject: e.target.value });
    if (e.target.value === "") this.setState({ subject_err: "Required Field" });
    else this.setState({ subject_err: "" });
  }
  handleChangeMessage(e) {
    this.setState({ message: e.target.value });
    if (e.target.message === "")
      this.setState({ message_err: "Required Field" });
    else this.setState({ message_err: "" });
  }

  handleSubmit1(e) {
    e.preventDefault();
    if (this.state.name === "") this.setState({ name_err: "Required Field" });
    if (this.state.email === "") this.setState({ email_err: "Required Field" });
    if (this.state.subject === "")
      this.setState({ subject_err: "Required Field" });
    if (this.state.message === "")
      this.setState({ message_err: "Required Field" });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.subject === "" ||
      this.state.message === ""
    ) {
      this.setState({ return_msg: "Fill All First", flag: true });
    } else {
      emailjs
        .send(
          "service_tc9elcm",
          "template_h6t7ov3",
          {
            from_name: this.state.name,
            message: this.state.message,
            from_email: this.state.email,
            subject: this.state.subject,
          },
          "a0J3snfXj_guLJfCQ"
        )
        .then(
          (response) => {
            this.setState({ name: "", message: "", email: "", subject: "" });
            toast.success("Email sent successfully.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            this.setState({ return_msg: "Success.", flag: true });
          },
          (error) => {
            toast.error("Failed to send email.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            this.setState({ return_msg: "Failed to send.", flag: true });
          }
        );
    }
  }
}

export default Contact;
