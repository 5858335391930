import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";

import img6 from "../assets/img/imgPart.jpg";

import img1 from "../assets/img/viven-asset-3.avif";
import img2 from "../assets/img/viven-asset-4.avif";
import img3 from "../assets/img/viven-asset-5.avif";
import img4 from "../assets/img/viven-asset-6.webp";

class Services extends Component {
  render() {
    const serviceListArray = [
      {
        image: img1,
        title: "Aircraft Charter",
        description:
        "Fly your yay: Experience the ultimate freedom with uur aircraft charter services. Tailored to your needs, we take you wherever your dreams soar.",
        link: "services",
      },

      {
        image: img6,
        title: "Aircraft Parts and Engines",
        description:
        "Keeping you airborne: Discover top-quality aircraft parts, engines and rotables. Our extensive inventory ensures smooth flights and uncompromised safety.",
        link: "services",
      },
      {
        image:img3,
        title: "Asset Management and Financing",
        description:"Empowering your fleet: Optimize efficiency with our aircraft lease and management solutions. Trust us to elevate your aircraft operations to new heights.",
        link: "services",
      },
      {
        image: img2,
        title: "Aircraft Sales and Leasing",
        description:
        "Unlock Your Skies: Our team specialized in aircraft sales and leasing, catering to a wide range of aircraft sizes from turboprop aircrafts such a cessna caravans, twin otters, ATRs to narrow-body jets like B737 and A320, our team boasts extensive experience in operating Cessna Caravans, Twin Otters, and ATR72s. By collaborating closely with our clients and civil aviation authorities, we ensure the seamless and secure execution of flight operations that align precisely with our client's requirements.",
        link: "services",
      },
      {
        image:img4,
        title: "Aviation consultanty",
        description:
          "Your Aviation Advisors: Viven Aviation is a leading aviation service provider, we stand for innovative and personalised services for our clients globally. Tailored services to source aircrafts, aircraft insurance, financing, crew & engineers sourcing, ferry flights globally. We work with aircraft owners/lessors to manage their assets with full compliance, safety and ensure that revenue is not compromised. Aircraft inspection, delivery & redelivery as per client needs.",
        link: "services",
      },
    ];

    const serviceListtMap = serviceListArray.map((valu, i) => {
      return (
        <div className="col-md-4 col-sm-12 " key={i}>
          <div className="service-item">
            <div className="img_serv">
              <Link>
                {" "}
                <img
                  style={{ height: "250px", width: "100%" }}
                  src={valu.image}
                  alt="service"
                />
              </Link>
            </div>
            <div className="service_text">
              <Link>
                <h4>{valu.title}</h4>
              </Link>
              <p>{valu.description}</p>
            </div>
            <Link className="serv_link">
              <i className="icon-glyph-40"></i>
            </Link>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <MetaTags>
          <title>Viven Aviation | Services</title>
          <meta name="description" content="Organic Food React JS Template." />
        </MetaTags>
        <LayoutOne>
          <div className="service-page">
            {/*====================  breadcrumb area ====================*/}

            <Breadcrumb title="Our Services" />

            {/*====================  End of breadcrumb area  ====================*/}

            <section className="service-section">
              <div className="animate_icon">
                <div className="animate_item animate_item1 bounce_animate">
                  <img src="assets/images/animate_icon1.png" alt="" />
                </div>
                <div className="animate_item animate_item2 bounce_animate">
                  <img src="assets/images/animate_icon2.png" alt="" />
                </div>
                <div className="animate_item animate_item3 bounce_animate">
                  <img src="assets/images/animate_icon3.png" alt="" />
                </div>
                <div className="animate_item animate_item4 bounce_animate">
                  <img src="assets/images/animate_icon4.png" alt="" />
                </div>
              </div>
              <div className="container">
                {/* Heading */}
                <div className="base-header">
                  <small>What We Offer</small>
                  <h3>Service we offer</h3>
                </div>
                {/* End: Heading */}

                <div
                  className="row"
                  style={{ justifyContent: "space-between" }}
                >
                  {serviceListtMap}
                </div>
              </div>
            </section>

            {/*==================== End : Service Section ====================*/}
          </div>
        </LayoutOne>
      </Fragment>
    );
  }
}

export default Services;
