import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne";
import Breadcrumb from "../components/Breadcrumb";
import { Fade, Slide } from "react-reveal";

import img1 from "../assets/img/img1.jpg";
import img2 from "../assets/img/img7.jpg";
import img3 from "../assets/img/img14.jpg";
import img4 from "../assets/img/img18.jpg";
import img5 from "../assets/img/img27.jpg";

class GalleryTwo extends Component {
  render() {
    const galleryListArray = [
      {
        image: img4,
      },
      {
        image: img2,
      },
      {
        image: img5,
      },
    ];

    const galleryListtMap = galleryListArray.map((valu, i) => {
      return (
        <div className="col-md-4 col-sm-12 web graphics" key={i}>
          <div
            className="single-project-item"
            style={{
              backgroundImage: `url(${valu.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="project-hover">
              <div className="project_cnt">
                <Link to="contact" className="slider_btn_one more-link ">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <div className="work-page-two">
          <section className="work-section">
            <div className="container">
              {/* Heading */}
              <div className="base-header">
                <small>Our Latest Work</small>
                <h3>Gallery </h3>
              </div>
              {/* End: Heading */}

              <div className="row projects-list">
                <Slide bottom>{galleryListtMap}</Slide>
              </div>
            </div>
          </section>

          {/*==================== End : Work Section ====================*/}
        </div>
      </Fragment>
    );
  }
}

export default GalleryTwo;
